body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

/* h1 {
  font-family: 'Bitter', serif !important;
} */

.tox-statusbar{
  display: none !important;
}

.html-container p{
  padding-top: 8px;
  padding-bottom: 8px;
}

input[type=checkbox] {
  accent-color: #000A0F !important;
  cursor: pointer !important;
}

.MuiFormControl-root{
  height: 50px !important;
  border: none !important;
  outline: none !important;
  width: 100% !important;
}

.MuiInputBase-root{
  height: 50px !important;
  border: 1px solid #727272 !important;
  outline: none !important;
  border-radius: 5px !important;
  font-weight: 400 !important;
  width: 100% !important;
}

.MuiOutlinedInput-notchedOutline{
  border: none !important;
}