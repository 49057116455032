/* .slide.selected{
    z-index: 0 !important;
}

.slide {
    transition-duration: 500ms !important;
    z-index: 0 !important;
    opacity: 0.6;
} */

.carousel img {
    pointer-events:inherit !important;
}

/* .carousel-slide {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .carousel-slide.slick-active {
    opacity: 1;
  }
   */